<template>
  <div class="company-bg">
    <img src="../../assets/company/phone.png" class="phone" />
    <div class="container">
      <div class="banner">
        <div class="banner-title">
          <span
            >2024年度<br />
            省级安全文化示范企业申报与复审</span
          >
        </div>
        <img src="../../assets/company/banner-ERS.png" class="banner-ERS" />
      </div>
      <div class="content-wrap">
        <!-- 如果是企业单位上面有选项卡 -->
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active" aria-current="page">企业申报</li>
          </ol>
        </nav>
        <!-- 选项卡 -->
        <ul class="nav nav-tabs nav-blue" id="myTab" role="tablist">
          <li
            class="nav-item"
            role="presentation"
            v-for="item in tabList"
            :key="item.code"
            @click="changeTab(item)"
          >
            <button
              :class="['nav-link', curCode == item.code ? 'active' : '']"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              {{ item.title }}
            </button>
          </li>
        </ul>
        <div v-if="curCode == 1">
          <div>
            <div class="title">
              河北省应急管理厅<br />
              关于做好2024年度省级安全文化建设示范企业<br />
              申报工作的通知
            </div>
            <p>各市（含定州、辛集市）、雄安新区应急管理局：</p>
            <p class="part">
              为深入开展我省企业安全文化建设工作，不断推动企业安全管理上质量、上水平，现就做好2024年度省级安全文化建设示范企业申报工作通知如下：
            </p>
            <p class="part">
              <b>一、省级安全文化建设示范企业申报</b>
            </p>
            <p class="part">（一）申报范围</p>
            <p class="part">
              在河北省境内从事生产经营活动、具有独立民事行为能力的企业单位。初次申报——即之前未申报、未被认定为省级安全文化建设示范企业。
            </p>
            <p class="part">（二）申报条件</p>
            <ol>
              <li>有专门的安全生产管理机构（煤矿企业除外）。</li>
              <li>
                申报前三年内（截至申报当年日期）未发生死亡或一次3人（含）以上重伤，或造成严重不良社会影响的生产安全责任事故；未发生重大社会影响的涉险事件；未纳入安全生产领域失信惩戒名单。
              </li>
              <li>开展安全文化建设工作至少满三年。</li>
              <li>符合《参考指标》的标准要求。</li>
            </ol>

            <p class="part">（三）申报材料</p>
            <p class="part">企业应提交以下申报材料：</p>
            <ol>
              <li>
                封面—标注***（企业规范名称）省级安全文化建设示范企业申报材料。
              </li>
              <li>目录—列清全册材料各项名称及页码范围。</li>
              <li>
                申请表。《河北省省级安全文化建设示范企业申请表》（见附件2）。
              </li>
              <li>
                自评表。对照《参考指标》（见附件1），由企业自评打分（见附件3）。
              </li>
              <li>
                企业安全文化建设工作报告。包括：企业概况、安全文化建设情况（安全理念文化、安全制度文化、安全行为文化、安全环境文化）、持续深化的目标计划，发挥示范引领作用典型经验做法。
              </li>
              <li>
                反映企业安全文化建设的佐证材料（图片、视频、论文、获奖证书等）。
              </li>
              <li>加盖申报企业印章的《营业执照》复印件。</li>
            </ol>

            <p class="part">
              <b>二、申报与办理</b>
            </p>
            <ol>
              <li>申报时间：2023年2月1日至5月31日。</li>
              <li>
                申报方式：企业按要求将申报材料报送至所在地的市级应急管理局，各市应急管理局依据《参考指标》进行初步审核，出具审核意见（附件4）。市应急管理局审核通过后，企业在“冀时安全”应急管理宣传教育平台进行网上申报。
                网上申报操作指南：微信扫描二维码查看<br /><br />
                <p><img width="200px" src="@/assets/gh.jpg" /></p>
              </li>

              <li>各市将企业纸质申报材料统一报送省应急管理厅。</li>
              <li>省应急管理厅组织对企业进行材料审核和现场复核。</li>
              <li>
                对通过评审的企业在网上进行公示公告，公告无异议，认定为“省级安全文化建设示范企业”。
              </li>
            </ol>

            <p class="part">
              <b>三、有关要求</b>
            </p>
            <p class="part">
              各市要高度重视，统筹协调，明确专人负责，精心组织实施，加大企业安全文化建设推进力度。要鼓励企业提高开展安全文化建设的积极性，对认定为省级安全文化建设示范企业的企业，要评定高的安全生产诚信等级，适当降低安全责任险费率，减少执法检查频次，支持安全生产科技项目立项、研发及成果转化。
            </p>
            <p class="part">
              省级安全文化建设示范企业申报系统将于2024年5月31日自动关闭，请企业按时间节点进行申报。相关附件从“冀时安全”应急管理宣传教育平台下载。
            </p>
            <p class="part" style="margin-bottom: 0px">
              联系人及电话：腰晓光 0311—87805091 13930402535
            </p>
            <p class="part" style="margin-bottom: 0px">
              邮寄地址：河北省石家庄市新华区国泰街18号
            </p>

            <div class="d-flex">
              <p class="part">附件：</p>
              <ol style="margin-left: 0">
                <li>河北省省级安全文化建设示范企业评价参考指标</li>
                <li>河北省省级安全文化建设示范企业申请表</li>
                <li>河北省省级安全文化建设示范企业自评表</li>
                <li>各市申报企业汇总表</li>
                <li>
                  关于***等单位2024年度省级安全文化建设示范企业申报审核意见（样本）
                </li>
              </ol>
            </div>

            <p class="text-right">河北省应急管理<br />2024年2月1日</p>
          </div>
        </div>
        <!--  <div v-if="curCode == 2">
         <div class="title">河北省省级安全文化示范企业复审名单</div>
         <p class="text-center">
            <img style="margin:20px auto" src="@/assets/none.png">
            2024年无复审企业
          </p> 
        </div> -->
        <div v-if="curCode == 3">
          <div class="title">省级安全文化建设示范企业申报工作相关附件</div>
          <div
            v-for="item in fileList.list"
            :key="item.id"
            :download="item.title"
          >
            <p>
              <a :href="item.path" target="_blank">{{ item.title }}</a>
            </p>
          </div>
        </div>
        <div
          v-if="
            curCode == 4 && applyInfo.status != -1 && userInfo.is_company == 2
          "
        >
          <div class="review-content">
            <img src="../../assets/company/icon-company-success.svg" />
            提交成功
          </div>
        </div>
        <!-- <div v-if="curCode == 4 && applyInfo.status == 1 && userInfo.is_company == 2">
          <div class="review-content">
            <img src="../../assets/company/icon-company-success.svg">
            企业申报审核通过
          </div>
        </div> -->
        <!-- <div
             v-if="curCode == 4 && applyInfo.status == -1 && userInfo.is_company == 2 && applyInfo.audit_history && applyInfo.audit_history.length > 0">
          <div class="review-content">
            <img src="../../assets/company/icon-company-fail.svg">
            企业申报审核失败
          </div>
        </div> -->
        <!-- <div v-if="curCode == 4 && !isReviewing"> -->
        <div v-if="curCode == 4" style="position: relative">
          <!-- 下面是遮罩层 -->
          <div
            class="btn-masker"
            v-if="userInfo.is_company == 2 && applyInfo.status == 0"
          ></div>

          <div
            class="title"
            v-if="userInfo.is_company == 2 && applyInfo.status == -1"
          >
            河北省省级安全文化示范企业<br />申请（复审）表
          </div>
          <div
            class="alert alert-danger"
            v-if="userInfo.is_company == 2 && applyInfo.status == -1"
          >
            在线申报/复审资料提交后无法修改，请务必仔细检查并慎重提交。如有疑问，请拨打咨询热线。
          </div>

          <form
            @submit.prevent="submitForm"
            v-if="userInfo.is_company == 2 && applyInfo.status == -1"
          >
            <div class="mb-3">
              <label class="form-label">申报类型<span>*</span></label>
              <div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.apply_type"
                    name="apply_type"
                    id="apply_type1"
                    value="1"
                  />
                  <label class="form-check-label" for="apply_type1"
                    >企业申报</label
                  >
                </div>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.apply_type"
                    name="apply_type"
                    id="apply_type2"
                    value="2"
                  />
                  <label class="form-check-label" for="apply_type2"
                    >企业复审</label
                  >
                </div>
              </div>
            </div>
            <!-- <div class="mb-3">
              <label class="form-label">推荐单位<span>*</span></label>
              <input
                type="text"
                v-model="formData.recommend_by"
                name="recommend_by"
                class="form-control"
              />
            </div> -->
            <div class="mb-3">
              <div class="row">
                <div class="col col-10">
                  <label class="form-label">申请企业<span>*</span></label>
                  <input
                    type="text"
                    v-model="formData.name"
                    name="name"
                    class="form-control"
                  />
                </div>
                <div class="col col-2">
                  <label class="form-label">邮编<span>*</span></label>
                  <input
                    type="number"
                    v-model="formData.postcode"
                    name="postcode"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col col-2">
                  <label class="form-label">地址<span>*</span></label>
                  <select
                    class="form-select"
                    value="河北省"
                    name="form.province"
                  >
                    <option selected>河北省</option>
                  </select>
                </div>
                <div class="col col-2">
                  <label class="form-label">&nbsp;</label>
                  <select
                    class="form-select"
                    @change="cityChanged"
                    name="form.city_code"
                  >
                    <option
                      v-for="item in cityList"
                      :key="item.area_id"
                      :value="item.area_id"
                    >
                      {{ item.area_name }}
                    </option>
                  </select>
                </div>
                <div class="col col-2">
                  <label class="form-label">&nbsp;</label>
                  <select
                    class="form-select"
                    @change="districtChanged"
                    name="form.district_code"
                  >
                    <option
                      v-for="item in districtList"
                      :key="item.area_id"
                      :value="item.area_id"
                    >
                      {{ item.area_name }}
                    </option>
                  </select>
                </div>
                <div class="col col-6">
                  <label class="form-label">&nbsp;</label>
                  <input
                    type="text"
                    placeholder="请输入具体地址"
                    v-model="formData.address"
                    name="address"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">隶属关系<span>*</span></label>
              <div>
                <div
                  class="form-check form-check-inline"
                  v-for="(item, index) in companyTypes"
                  :key="item.value"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.company_type"
                    :id="'company_type_' + index"
                    :value="item.value"
                  />
                  <label
                    class="form-check-label"
                    :for="'company_type_' + index"
                    >{{ item.lable }}</label
                  >
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">所属行业<span>*</span></label>
              <div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.industry"
                    name="industry"
                    id="industry1"
                    value="煤矿"
                  />
                  <label class="form-check-label" for="industry1">煤矿</label>
                </div>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.industry"
                    name="industry"
                    id="industry2"
                    value="非煤矿山"
                  />
                  <label class="form-check-label" for="industry2"
                    >非煤矿山</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.industry"
                    name="industry"
                    id="industry3"
                    value="化工"
                  />
                  <label class="form-check-label" for="industry3">化工</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.industry"
                    name="industry"
                    id="industry4"
                    value="电力"
                  />
                  <label class="form-check-label" for="industry4">电力</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.industry"
                    name="industry"
                    id="industry5"
                    value="冶金"
                  />
                  <label class="form-check-label" for="industry5">冶金</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.industry"
                    name="industry"
                    id="industry6"
                    value="交通运输"
                  />
                  <label class="form-check-label" for="industry6"
                    >交通运输</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.industry"
                    name="industry"
                    id="industry7"
                    value="建筑施工"
                  />
                  <label class="form-check-label" for="industry7"
                    >建筑施工</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.industry"
                    name="industry"
                    id="industry8"
                    value="加工制造"
                  />
                  <label class="form-check-label" for="industry8"
                    >加工制造</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.industry"
                    name="industry"
                    id="industry9"
                    value="烟花爆竹"
                  />
                  <label class="form-check-label" for="industry9"
                    >烟花爆竹</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.industry"
                    name="industry"
                    id="industry10"
                    value="特种设备"
                  />
                  <label class="form-check-label" for="industry10"
                    >特种设备</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.industry"
                    name="industry"
                    id="industry11"
                    value="民用爆炸物品"
                  />
                  <label class="form-check-label" for="industry11"
                    >民用爆炸物品</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.industry"
                    name="industry"
                    id="industry12"
                    value="其他"
                  />
                  <label class="form-check-label" for="industry12">其他</label>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col col-2">
                  <label class="form-label">企业代表人<span>*</span></label>
                  <input
                    type="text"
                    v-model="formData.legal_person"
                    name="legal_person"
                    class="form-control"
                  />
                </div>
                <div class="col col-2">
                  <label class="form-label">电话<span>*</span></label>
                  <input
                    type="tel"
                    v-model="formData.legal_phone"
                    name="legal_phone"
                    class="form-control"
                  />
                </div>
                <div class="col col-2">
                  <label class="form-label">传真<span>*</span></label>
                  <input
                    type="tel"
                    v-model="formData.legal_fax"
                    name="legal_fax"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col col-2">
                  <label class="form-label">安全文化负责人<span>*</span></label>
                  <input
                    type="text"
                    v-model="formData.manager"
                    name="manager"
                    class="form-control"
                  />
                </div>
                <div class="col col-2">
                  <label class="form-label">电话<span>*</span></label>
                  <input
                    type="tel"
                    v-model="formData.manager_phone"
                    name="manager_phone"
                    class="form-control"
                  />
                </div>
                <div class="col col-2">
                  <label class="form-label">手机<span>*</span></label>
                  <input
                    type="tel"
                    v-model="formData.manager_mobile"
                    name="manager_mobile"
                    class="form-control"
                  />
                </div>
                <div class="col col-2">
                  <label class="form-label">传真<span>*</span></label>
                  <input
                    type="tel"
                    v-model="formData.manager_fax"
                    name="legal_fax"
                    class="form-control"
                  />
                </div>
                <div class="col col-4">
                  <label class="form-label">邮箱<span>*</span></label>
                  <input
                    type="email"
                    v-model="formData.manager_email"
                    name="manager_email"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label"
                >企业安全生产工作管理职能部门<span>*</span></label
              >
              <input
                type="text"
                v-model="formData.department"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <label class="form-label"
                >企业安全文化建设职能部门设置及人员情况<span>*</span></label
              >

              <textarea
                class="form-control"
                placeholder=""
                v-model="formData.staff_info"
                style="height: 100px"
              ></textarea>
            </div>
            <!-- 新企业 -->
            <div class="mb-3" v-if="formData.apply_type == 1">
              <div class="row">
                <div class="col col-6">
                  <label class="form-label"
                    >企业安全文化建设工作启动时间<span>*</span></label
                  >
                  <input
                    type="text"
                    v-model="formData.runup_time"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <!-- 复审企业 -->
            <div class="mb-3" v-if="formData.apply_type == 2">
              <div class="row">
                <div class="col col-6">
                  <label class="form-label"
                    >企业“省级安全文化示范企业”取证年度<span>*</span></label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label"
                >近三年是否有死亡或一次3人（含）以上重伤生产安全责任事故<span
                  ><span>*</span></span
                ></label
              >
              <div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.is_warning"
                    id="warning_1"
                    value="1"
                  />
                  <label class="form-check-label" for="warning_1">是</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="formData.is_warning"
                    id="warning_2"
                    value="0"
                  />
                  <label class="form-check-label" for="warning_2">否</label>
                </div>
              </div>
            </div>
            <table
              class="table form-table text-center mb-3"
              v-if="formData.is_warning == 1"
            >
              <tbody>
                <tr>
                  <td>前年</td>
                  <td>去年</td>
                  <td>今年（至月份）</td>
                </tr>
                <tr>
                  <td>
                    <div class="row">
                      <div class="col">起数</div>
                      <div class="col">人数</div>
                    </div>
                  </td>
                  <td>
                    <div class="row">
                      <div class="col">起数</div>
                      <div class="col">人数</div>
                    </div>
                  </td>
                  <td>
                    <div class="row">
                      <div class="col">起数</div>
                      <div class="col">人数</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="row">
                      <div class="col">
                        <input
                          type="text"
                          v-model="formData.warning_info[0].serious"
                          class="form-control"
                          placeholder="重伤"
                        />
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          v-model="formData.warning_info[0].minor"
                          class="form-control"
                          placeholder="轻伤"
                        />
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          v-model="formData.warning_info[0].serious_person"
                          class="form-control"
                          placeholder="重伤"
                        />
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          v-model="formData.warning_info[0].minor_person"
                          class="form-control"
                          placeholder="轻伤"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="row">
                      <div class="col">
                        <input
                          type="text"
                          v-model="formData.warning_info[1].serious"
                          class="form-control"
                          placeholder="重伤"
                        />
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          v-model="formData.warning_info[1].minor"
                          class="form-control"
                          placeholder="轻伤"
                        />
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          v-model="formData.warning_info[1].serious_person"
                          class="form-control"
                          placeholder="重伤"
                        />
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          v-model="formData.warning_info[1].minor_person"
                          class="form-control"
                          placeholder="轻伤"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="row">
                      <div class="col">
                        <input
                          type="text"
                          v-model="formData.warning_info[2].serious"
                          class="form-control"
                          placeholder="重伤"
                        />
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          v-model="formData.warning_info[2].minor"
                          class="form-control"
                          placeholder="轻伤"
                        />
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          v-model="formData.warning_info[2].serious_person"
                          class="form-control"
                          placeholder="重伤"
                        />
                      </div>
                      <div class="col">
                        <input
                          type="number"
                          v-model="formData.warning_info[2].minor_person"
                          class="form-control"
                          placeholder="轻伤"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mb-3">
              <label class="form-label"
                >企业安全文化建设工作报告<span>*</span></label
              >
              <textarea
                class="form-control"
                v-model="formData.work_report"
                maxlength="3000"
                placeholder="包括：企业概况、安全文化示范企业建设情况（安全理念文化、安全制度文化、安全环境文化、安全行为文化）、持续深化的目标计划，发挥示范引领作用典型经验做法等；复审企业重点是近三年来安全文化建设基本情况，安全文化建设取得的经验、成效，安全文化理论与实践创新，示范作用的发挥情况等。可另附材料，限3000字以内）"
                style="height: 485px"
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="formFile" class="form-label"
                >另附材料上传<span>*</span
                ><span
                  ><br /><small
                    >（上传“工作通知〞中企业申报/复审所需的全部材料,
                    可上传多个;上传文件只能是 jpg / png / gif / doc /docx
                    /pdf格式 上传文件大小不能超过 300MB）</small
                  ></span
                ></label
              >

              <input
                class="form-control"
                type="file"
                ref="uploadInput"
                multiple
                accept=".jpg,.png,.doc,.docx,.gif,.pdf"
                @change="updateFile($event)"
                id="formFile"
              />
            </div>
            <div class="col-md-12 mb-3">
              <div class="thumb-content">
                <div
                  class="thumb-content-item"
                  v-for="(item, index) in thumbList"
                  :key="item"
                >
                  <!-- <img width="16"
                       src="../../assets/company/icon_doc.png" /> -->
                  <div>{{ uploadFiles[index].name }}</div>
                  &nbsp;&nbsp;
                  <i class="iconfont icon-clear" @click="deleteFile(index)"></i>
                </div>
              </div>
              <!-- <input class="form-control"
                     type="file"
                     id="formFileMultiple"
                     @change="updateFile($event)"
                     multiple /> -->
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col col-6">
                  <label class="form-label"
                    >企业按照《参考指标》自评得分<span>*</span></label
                  >
                  <input
                    type="number"
                    v-model="formData.self_score"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="mb-5">
              <label class="form-label">企业自评意见<span>*</span></label>
              <textarea
                class="form-control"
                placeholder=""
                v-model="formData.self_comment"
                style="height: 100px"
              ></textarea>
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-default btn-lg">取消</button>
              <button type="submit" class="btn btn-primary btn-lg">
                确认提交
              </button>
            </div>
          </form>
        </div>
        <div
          v-if="
            curCode == 5 && applyInfo.status != -1 && userInfo.is_company == 2
          "
        >
          <div class="review-content">
            <img src="../../assets/company/icon-wait.svg" />
            正在审核中，请等待...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  areaList,
  companyRecheck,
  fetchFileList,
  fetchRecheckList,
  getBaseData,
  getUploadInfo,
  myApply,
  userDetail,
} from "@/network/API.js";
import COS from "cos-js-sdk-v5";
import tools from "../../utils/tools";
export default {
  data() {
    return {
      fileHost: "https://vote-image.hbzhengwu.com/",
      formData: {
        apply_type: 1,
        is_warning: 1,
        province_code: "130000",
        province: "河北省",
        companyTypes: [],
        industry: [],
        warning_info: [
          {
            year: 2021,
            minor: "", // 轻伤次数
            serious: "", // 重伤次数
            minor_person: "", // 轻伤人数
            serious_person: "", // 重伤人数
          },
          {
            year: 2022,
            minor: "", // 轻伤次数
            serious: "", // 重伤次数
            minor_person: "", // 轻伤人数
            serious_person: "", // 重伤人数
          },
          {
            year: 2023,
            minor: "", // 轻伤次数
            serious: "", // 重伤次数
            minor_person: "", // 轻伤人数
            serious_person: "", // 重伤人数
          },
        ],
      },
      curCode: 1,
      tabList: [
        {
          code: 1,
          title: "工作通知",
        },

        {
          code: 3,
          title: "附件下载",
        },
        {
          code: 4,
          title: "在线申报/复审",
        },
        {
          code: 5,
          title: "在线申报/复审结果",
        },
      ],
      fileList: [], //附件列表
      recheckList: [], //审核名单
      cityList: [],
      districtList: [],
      selCity: {},
      selDistrict: {},
      isReviewing: false,
      userInfo: {},
      applyInfo: {
        status: -1,
        audit_history: [],
      },
      uploadFiles: [],
      thumbList: [],
      cos: null,
      loader: null,
    };
  },
  async created() {
    this.userInfo = await userDetail();
    this.fileList = await fetchFileList();
    this.recheckList = await fetchRecheckList();
    this.getAreaList();
    this.companyTypes = await getBaseData("company_type");
    let info = await myApply(); //audit_history 申报历史
    if (info.apply_id) {
      this.applyInfo = info;
    }
    if (this.applyInfo.warning_info) {
      this.applyInfo.warning_info = JSON.parse(this.applyInfo.warning_info);
    }
    this.formData = {
      ...this.formData,
      ...this.applyInfo,
    };

    this.cos = new COS({
      getAuthorization: async (options, callback) => {
        let data = await getUploadInfo();
        let credentials = data.temp_key.credentials;
        let params = {
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          SecurityToken: credentials.sessionToken,
          StartTime: data.temp_key.startTime, // 时间戳，单位秒，如：1580000000
          ExpiredTime: data.temp_key.expiredTime, // 时间戳，单位秒，如：1580000000
        };
        callback(params);
      },
    });

    // if (this.applyInfo.city) {
    //   this.formData.city = this.applyInfo.city;
    //   this.formData.city_code = this.applyInfo.city_code;
    //   let curCity = this.cityList.filter((item) => {
    //     return item.area_name == this.applyInfo.city;
    //   });
    //   if (curCity.length > 0) {
    //     this.getAreaList(curCity[0].area_id);
    //   }
    // }
    // if (this.applyInfo.district) {
    //   this.districtList.filter((item) => {
    //     return item.area_id == this.applyInfo.district;
    //   });
    // }
  },
  methods: {
    deleteFile(idx) {
      // this.$refs.uploadInput.files.splice(idx, 1)
      this.thumbList.splice(idx, 1);
      this.uploadFiles.splice(idx, 1);
    },
    updateFile(event) {
      const files = event.target.files;
      var fileTypes = [".jpg", ".png", ".gif", ".doc", ".docx", ".pdf"];
      let that = this;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        var fileend = file.name.substring(file.name.lastIndexOf("."));
        if (file.size / 1024 > 1024 * 300) {
          this.$toast("文件过大,仅支持小于300M的文件");
          // event.target.value = "";
          break;
        }
        if (fileTypes.indexOf(fileend) == -1) {
          this.$toast("不支持的文件类型");
          // event.target.value = "";
          break;
        }
        this.uploadFiles.push(file);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          that.thumbList.push(this.result);
        };
      }
      event.target.value = "";
    },
    cityChanged(e) {
      console.log("city canged");
      let cityId = e.target.value;
      this.selCity = this.cityList.find((item) => item.area_id == cityId);
      this.getAreaList(cityId);
    },
    districtChanged(e) {
      let districtId = e.target.value;
      this.selDistrict = this.districtList.find(
        (item) => item.area_id == districtId
      );
    },
    changeTab(t) {
      this.curCode = t.code;
    },
    toDownloadFile(file) {},
    async getAreaList(areaId) {
      let list = await areaList({
        code: areaId ? areaId : "130000",
      });
      if (areaId) {
        this.districtList = list;
        this.selDistrict = list[0];
        this.selCity = this.cityList.find((item) => item.area_id == areaId);
      } else {
        this.cityList = list;
        this.getAreaList(this.cityList[0].area_id);
      }
    },
    async submitForm(event) {
      if (this.selCity) {
        this.formData.city_code = this.selCity.area_id;
        this.formData.city = this.selCity.area_name;
      }
      if (this.selDistrict) {
        this.formData.district = this.selDistrict.area_name;
        this.formData.district_code = this.selDistrict.area_id;
      }
      console.log(this.formData);
      // if (!this.formData.recommend_by) {
      //   this.$toast("请输入推荐单位");
      //   return;
      // }
      if (!this.formData.name) {
        this.$toast("请输入申请企业");
        return;
      }
      if (!this.formData.postcode) {
        this.$toast("请输入邮编");
        return;
      }
      if (!this.formData.province) {
        this.$toast("请完善区域");
        return;
      }
      if (!this.formData.city) {
        this.$toast("请完善区域");
        return;
      }
      if (!this.formData.district) {
        this.$toast("请完善区域");
        return;
      }
      if (!this.formData.address) {
        this.$toast("请完善具体地址");
        return;
      }
      if (!this.formData.company_type) {
        this.$toast("请选择隶属关系");
        return;
      }
      if (!this.formData.industry) {
        this.$toast("请选择所属行业");
        return;
      }
      if (!this.formData.legal_person) {
        this.$toast("请输入企业代表人");
        return;
      }
      if (!this.formData.legal_phone) {
        this.$toast("请输入电话");
        return;
      }
      if (!this.formData.legal_fax) {
        this.$toast("请输入传真");
        return;
      }
      if (!this.formData.manager) {
        this.$toast("请输入安全文化负责人");
        return;
      }
      if (!this.formData.manager_phone) {
        this.$toast("请输入电话");
        return;
      }
      if (!this.formData.manager_mobile) {
        this.$toast("请输入手机");
        return;
      }
      if (!this.formData.manager_fax) {
        this.$toast("请输入传真");
        return;
      }
      if (!this.formData.manager_email) {
        this.$toast("请输入邮箱");
        return;
      }
      if (!this.formData.department) {
        this.$toast("请输入企业安全生产工作管理职能部门");
        return;
      }
      if (!this.formData.staff_info) {
        this.$toast("请输入企业安全文化建设职能部门设置及人员情况");
        return;
      }
      if (!this.formData.runup_time && this.formData.apply_type == 1) {
        this.$toast("请输入企业安全文化建设工作启动时间");
        return;
      }
      if (!this.formData.runup_time && this.formData.apply_type == 2) {
        this.$toast("请输入企业“省级安全文化示范企业”取证年度");
        return;
      }
      if (!this.formData.work_report) {
        this.$toast("请输入企业安全文化建设工作报告");
        return;
      }
      if (!this.formData.self_score) {
        this.$toast("请输入自评得分");
        return;
      }
      if (!this.formData.self_comment) {
        this.$toast("请输入企业自评意见");
        return;
      }

      if (this.formData.is_warning == 0) {
        delete this.formData.warning_info;
      }
      if (this.uploadFiles.length == 0) {
        this.$toast("请上传附件");
        return;
      }
      this.loader = this.$loading.show();
      if (this.uploadFiles.length > 0) {
        var fileUrls = [];
        for (let i = 0; i < this.uploadFiles.length; i++) {
          let formdata = new FormData();
          formdata.append("file", this.uploadFiles[i]);
          try {
            let res = await this.uploadFileToCloud(this.uploadFiles[i]);
            fileUrls.push(tools.cloudPathToLocal(res.Location));
          } catch (error) {
            this.$toast(err);
          }
        }
        this.formData.attachment = fileUrls.join(",");
      }

      let res = await companyRecheck({
        ...this.formData,
      });

      let info = await myApply(); //audit_history 申报历史
      this.applyInfo.audit_history = info;
      this.applyInfo.status = 0;
      this.isReviewing = true;
      this.loader.hide();
    },
    uploadFileToCloud(file) {
      return new Promise((resolve, reject) => {
        this.cos.putObject(
          {
            Bucket: "votes-1301974940" /* 填入您自己的存储桶，必须字段 */,
            Region: "ap-beijing" /* 存储桶所在地域，例如ap-beijing，必须字段 */,
            Key: `uploads/mem/doc/${this.userInfo.member_id}/${file.name}` /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
            Body: file /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */,
            onTaskReady: function (taskId) {
              /* taskId可通过队列操作来取消上传cos.cancelTask(taskId)、停止上传cos.pauseTask(taskId)、重新开始上传cos.restartTask(taskId) */
              console.log(taskId);
            },
            // 支持自定义headers 非必须
            Headers: {
              "x-cos-meta-test": 123,
            },
          },
          function (err, data) {
            if (err) {
              console.log("upload err " + err);
              reject(`附件上传失败, ${err}`);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.banner-ERS {
  position: absolute;
  right: 0px;
  top: 40px;
  width: 368px;
  height: 370px;
  display: block;
}

.title {
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.5em;
  color: #222;
  margin-bottom: 20px;
}
.alert-danger {
  position: relative;
  margin-bottom: var(--bs-alert-margin-bottom);
  background: #f8d7da;
  color: #b02a37;
  border-radius: 6px;
  padding: 16px;
  border: 1px solid #f1aeb5;
  margin-bottom: 20px;
}
form {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.form-label span {
  color: red;
}

.nav-tabs {
  justify-content: space-around;
  margin-bottom: 40px;
}

p {
  line-height: 2em;
}

p.part {
  text-indent: 32px;
}
ol {
  margin-left: 32px;
}
ol li {
  line-height: 2em;
}
.text-right {
  text-align: right;
}

.form-table {
  background: #f4f4f4;
}

.form-table td {
  border-right: 1px solid #ced4da;
}

.form-table td:last-child {
  border-right: none;
}

.review-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
  min-height: 300px;
}

.review-content img {
  width: 200px;
  margin-bottom: 20px;
  padding-left: 50px;
}

.btn-masker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}
.phone {
  position: fixed;
  right: 0;
  top: 200px;
  width: 183px;
  height: 74px;
  z-index: 9;
}
.thumb-content-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #0d6efd;
}
.thumb-content-item .iconfont {
  cursor: pointer;
}
</style>
